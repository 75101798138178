import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo_header.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://pruebasconvocatorias.aicor.es/';
params['apiUrl'] = 'https://api.pruebasconvocatorias.aicor.es/api/';
params['loginAdminImg'] = loginAdminImg;
params['sideBarIcon'] = iconImg;
params['sideBarImg'] = sideBarImg;
params['sideBarColor'] = '#B00202';
params['firstColorRGB'] = '176, 2, 2';
params['firstColor'] = '#B00202';
params['firstColorHover'] = '#B00202';
params['secondColor'] = '#D9D5F5';
params['color_green'] = '#00C534';
params['color_red'] = '#FF0C56';
params['color_grey'] = '#BBBBBB';
params['proyectName'] = 'CONVOCATORIAS';
params['defaultTableLength'] = 10;
params['tableLengthArray'] = [10, 25, 50];
params['personalizacion'] = false;
params['dev_mode'] = true;
params['panel_url'] = 'administrador';
params['web_url'] = 'entidad';

export default params;
